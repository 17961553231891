import React, { useEffect, useState } from 'react';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import BestFacilities from '../../components/shared/BestFacilities';
import { Helmet } from 'react-helmet';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import Config from '../../../helpers/Config';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';


const FaqPage = () => {
    const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [langCode, setLangCode] = useState('');

    useEffect(() => {
        // declare the data fetching function
        const dataOperationFunc = async () => {
            let lnCode = getLanguageCodeFromSession();
            setLangCode(lnCode);

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["FaqPage"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                await setLocalizationLabelsArray(arryRespLocalization);
            }
        }
        // call the function
        dataOperationFunc().catch(console.error);
    }, [])



    return (
        <>
            <Helmet>
                <title>{siteTitle} - Frequently Asked Questions (FAQ)</title>
                <meta name="description" content={siteTitle + " - Frequently Asked Questions (FAQ)"} />
                <meta name="keywords" content="Frequently Asked Questions, FAQ"></meta>
            </Helmet>

            <SiteBreadcrumb title="FAQ's" />

            <section className="faq-area ptb-60">
                <div className="container">
                    <div className="section-title">
                        <h2><span className="dot"></span>
                            <span id="lbl_faq_pagetitle">

                                {LocalizationLabelsArray.length > 0 ?
                                    replaceLoclizationLabel(LocalizationLabelsArray, "Frequently Asked Questions", "lbl_faq_pagetitle")
                                    :
                                    "Frequently Asked Questions"
                                }
                            </span>
                        </h2>
                    </div>

                    <div className="faq-accordion">
                        <ul className="accordion">
                            <Accordion>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        How to place an order on Asuremart?

                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                    <ul>
                                       <li>Go the Home page select your products according to your needs </li>
                                       <li> Then add the products to the cart then</li>
                                       <li>Proceed to Checkout on place order and fill your details your order will be placed.</li>


                                      </ul>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        How to report return / exchange claim?

                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        You have to check when are you received. Otherwise Not Return and  Exchange
</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        How to check the completed orders (for registered members)?

                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <ul>
                                        Go to My Profile click on VIEW ORDER HISTORY , your orders will be display there.
                                        </ul>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        How to check the cancel orders ?

                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        Go to My Profile click on VIEW ORDER HISTORY , your orders will be display there.
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        Why is the Asuremart app special?

                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                    <ul>
                                       <li> No need to go for shop to buy hardwares get hardwares at your doorstep. </li> 
                                       <li>Fast delivery (24X7 available).</li>
                                       <li>Safe and secure options.</li>
                                       <li>Delivered to both urban and rural areas</li>
                                    </ul>
                                        
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        Why is there extra charge for certain items?

                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        Special items or Special Destination.

                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        What is the difference between the Asuremart member and guest ?

                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                       <p>Asuremart member receives the offers , coupon , discounts ..(They will get a notification about every offers and discounts).
</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </ul>
                    </div>
                </div>
            </section>

            <BestFacilities />

        </>
    );

}

export default FaqPage;
