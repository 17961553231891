import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Config from '../../../helpers/Config';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import BestFacilities from '../../components/shared/BestFacilities';
import about1 from '../../resources/themeContent/images/theme-images/about1.jpg';
import about2 from '../../resources/themeContent/images/theme-images/about2.jpg';
import signature from '../../resources/themeContent/images/theme-images/signature.png';


const About = () => {
  const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);

  return (
    <>


      <Helmet>
        <title>{siteTitle} - About Us</title>
        <meta name="description" content={siteTitle + " - About us page"}  />
        <meta name="keywords" content="About us"></meta>
      </Helmet>

      <SiteBreadcrumb title="About Us" />

      <section className="about-area ptb-60">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <p><b>Asuremart </b>online sells products available in hardware stores. Asuremart will only sell products MADE IN INDIA. Asuremart is set to launch in Tamil Nadu and Pondicherry for the first published. It is there to launch across India in every phase. Asuremart is very keen to maximize Indian products and promote them. Asuremart like to support to Manufacturer and Buyer.
</p>
<p><b>Manufacturer benefit to Joint in Asuremart</b><br></br>
A lot of Indian manufacturers have a lot of strength and efficiency in production. But they are reluctant to control the cost of production and to advertise, sell, or distribute manufactured goods. 
</p>
<p>
<b>Asuremart is emerging to help.</b>
Manufacturer can sell products for FREE on the Asuremart site. We make a place for that. We will take your products to all the customers from the small village of onwards.
We have established our distributors in every district for you. They will take you to the Asuremart site where your items are registered from each village. From every village, they can find those items created in your product on their mobile phones.
Asuremart will advertise your products for you. We advertise your products in newspapers and on the internet and via SMS and in many ways. 
Buyer bought Manufacturer stuff through the Asuremart site. All you have to do is pack those things in your place. Asuremart will add those items for you to the buyers. We will take your products safely to the buyers without wasting them in any way during any season. Even strangers can find out about your products by selling them on our site. That way you can increase productivity more. You can focus on trying to maximize productivity. Manufacturer do not even have to worry about the money you are selling. Will automatically come into Manufacturer bank account. All of these Manufacturer get for free

</p>
<p>
<b>Benefit to Buyer Use Asuremart web site </b><br></br>
Asuremart is the only website in India that sells only Indian manufactured products On the Asuremart website, Buyer can buy the hardware items Buyer need through buyer mobile phone. No need to wander around looking for the hardware items you need. You can buy it through the Asuremart website. If buyer want to buy a product, Buyer can check the quality and price of the product. It is only possible through the Asuremart website. When buyer need to buy an item, Buyer can see many companies that can produce the same product through the Asuremart website. Through it buyer can see the different prices, and quality of products that many companies can produce. buyer can buy the highest quality products at the lowest prices through the Asuremart website. Even items you have never seen or heard of can be found on the Asuremart website. Directly in your hand from the manufacturer. No need to wander around looking for hardware items anymore. Asuremart for you. 
</p>
<p>We support Indian products that can be made in India.
</p><p> We will improve the sell Indian product.
</p>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <img src={about1} className="about-img1" alt="image" />
                {/* <img src={about2} className="about-img2" alt="image" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>



      <BestFacilities />

    </>
  );

}

export default About;
